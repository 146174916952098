import React from 'react'

function Friends() {
    return (
        <div className="container center-body general-body-background">
            <p>Need to build the Following website.</p>
        </div>
    )
}

export default Friends
